import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const YahooAuthCallback = () => {
    const location = useLocation();
    const [error, setError] = useState(null);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const code = params.get('code');

        if (code) {
            const redirectUrl = `sportsethos://yahoo-callback?code=${code}`;
            window.location.replace(redirectUrl);
        } else {
            setError('Authorization code not found in the URL.');
        }
    }, [location]);

    return (
        <div>
            {error && <p style={{ color: 'red' }}>{error}</p>}
        </div>
    );
};

export default YahooAuthCallback;